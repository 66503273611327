import $ from 'jquery';

import 'fotorama/fotorama.js';
import 'fotorama/fotorama.css';

import pannellumViewer from 'components/pannellum';
import slickSlider from 'components/slickSlider';

// Swiper
import { Autoplay, Navigation, Pagination, Swiper } from 'components/swipperSlider';

// PhotoSwipe
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import '../contrib/news';
import '../contrib/comments';

window.addEventListener('DOMContentLoaded', () => {
    slickSlider('#slick-slider');

    const slider2 = slickSlider('#slick-slider2', { desktop: { dots: false }});

    if (slider2) {
        $('#slick-slider2-nav')
            .on('mouseenter', '[data-index]', (e) => {
                slider2
                    .slick('slickSetOption', { waitForAnimate: false })
                    .slick('slickGoTo', e.target.dataset.index, false)
                    .slick('slickPause');
            })
            .on('mouseleave', '[data-index]', () => {
                slider2
                    .slick('slickSetOption', { waitForAnimate: true })
                    .slick('slickPlay');
            })
        ;
    }

    document.querySelectorAll('div[data-panorama]').forEach(pannellumViewer);

    const lightbox = new PhotoSwipeLightbox({
        gallery: '#slide-news',
        children: 'a',
        pswpModule: () => import('photoswipe')
    });

    lightbox.init();

    new Swiper('.swiper', {
        modules: [Autoplay, Navigation, Pagination],
        autoplay: {
            delay: 4000,
        }
    });
});
