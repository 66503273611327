import 'pannellum/src/js/libpannellum';
import 'pannellum/src/js/pannellum';
import 'pannellum/src/css/pannellum.css';

import './styles.scss';

function pannellumViewer(elm: HTMLDivElement): void {
    window.pannellum.viewer(elm, {
        'type': 'equirectangular',
        'panorama': elm.dataset.panorama,
        'autoLoad': true,
        'autoRotate': -3,
        'compass': false,
        'mouseZoom': false
    });
}

export default pannellumViewer;
